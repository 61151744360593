.site-header {
  margin-top: 1.5rem;
  margin-bottom: 5rem;
}

.site-title {
  color: var(--color-medium-accent);
  font-weight: 500;
  text-align: center;
  margin: 1rem;
}

.site-title a:any-link {
  text-decoration: inherit;
  color: inherit;
}

.navbar {
  text-align: center;
}

.navbar-link:any-link {
  color: var(--color-medium-gray);
  font-weight: 600;
  text-decoration: none;
  transition-property: color;
  transition-duration: 0.15s;
  margin: 0 1rem;
}

.navbar-link:hover {
  color: var(--color-medium-accent);
  transition-property: color;
  transition-duration: 0.15s;
}

/* Highlights the current page in the navbar */
.navbar-link.active {
  color: var(--color-medium-accent);
}

/* Mobile */
@media (max-width: 900px) {
  .site-header {
    margin-bottom: 4rem;
  }
}

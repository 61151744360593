@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

:root {
  --desktop-max-width: 1280px;
  --full-content-width: min(var(--desktop-max-width), 95%);
  --safe-content-width: calc(var(--full-content-width) * 0.8);
  --narrow-content-width: calc(var(--full-content-width) * 0.6);
  --bottom-page-margin: 2rem;

  --default-border-radius: 4px;
  --color-light-gray: #dedede;
  --color-medium-gray: #727272;
  --color-medium-gray-rgb: 114, 114, 114;
  --color-dark-gray: #333333;
  --color-medium-accent-rgb: 116, 84, 67;
  --color-medium-accent: rgb(var(--color-medium-accent-rgb));
  --color-dark-accent: hsl(21, 27%, 30%);
}

.text-center {
  text-align: center;
}

.page-container {
  margin-bottom: var(--bottom-page-margin);
}

.narrow-container {
  margin: auto;
  width: var(--narrow-content-width);
}

.safe-container {
  margin: auto;
  width: var(--safe-content-width);
}

.page-title {
  margin-bottom: 3rem;
  color: rgba(var(--color-medium-accent-rgb), 80%);
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  color: var(--color-dark-gray);
  /* Permenant scrollbar, to avoid site header jumping to recenter on pages without a scrollbar  */
  overflow-y: scroll;
}

.double-spaced {
  line-height: 1.8em;
  font-size: 1.0625em;
}

h1 {
  font-size: 4.5rem;
  font-weight: 600; /* Semibold */
  color: var(--color-medium-gray);
  margin: 0;
}

h2 {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--color-medium-gray);
  margin: 0;
}

h3 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--color-dark-gray);
  margin: 0;
}

h4 {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--color-dark-gray);
  margin: 0;
}

/* default link styling */
a:any-link {
  color: var(--color-medium-accent);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* selected link */
a:active {
  color: var(--color-dark-accent);
}

/* Mobile */
@media (max-width: 900px) {
  :root {
    --full-content-width: 90%;
    --safe-content-width: var(--full-content-width);
    --narrow-content-width: var(--full-content-width);
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.25rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.25rem;
  }
}

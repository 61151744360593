.contact-image {
  width: 55%;
  display: block;
  margin-left: auto;
}

/* Mobile */
@media (max-width: 900px) {
  .contact-image {
    margin-top: 3rem;
    width: 100%;
  }
}
